<script>
  import BaseModal from '@/components/BaseModal.vue';
  export default {
    components: { BaseModal },
    computed: {
      isSystemOnMacOS() {
        return navigator.platform.includes('Mac');
      },
      commandOrControl() {
        return !this.isSystemOnMacOS ? 'Ctrl' : '⌘';
      },
    },
  };
</script>
<template>
  <base-modal @close="$emit('close')">
    <div class="max-w-610 space-y-6 p-8">
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-4">
          <div class="flex h-10 w-10 items-center justify-center rounded-full bg-main-color-100">
            <inline-svg src="/img/icons/shortcuts-page-icon.svg" class="h-5 w-5 fill-white" />
          </div>
          <span class="text-2xl font-bold">Shortcuts</span>
        </div>
        <button @click="$emit('close')">
          <inline-svg src="/img/icons/close.svg" />
        </button>
      </div>
      <p class="text-lg text-gray-80">Speed up your workflow with these handy shortcuts.</p>

      <div class="flex items-center gap-1">
        <span class="text-lg font-medium text-gray-80">{{ `${commandOrControl} ` }} + Enter:</span>
        <span class="text-lg font-medium">Send the reply</span>
      </div>
      <div class="flex items-center gap-1">
        <span class="text-lg font-medium text-gray-80"
          >{{ `${commandOrControl} ` }} + Shift + Enter:</span
        >
        <span class="text-lg font-medium">Send the reply and like</span>
      </div>
      <div class="flex items-center gap-1">
        <span class="text-lg font-medium text-gray-80"
          >{{ `${commandOrControl} ` }} + Shift + {{ isSystemOnMacOS ? 'O' : 'L' }}:</span
        >
        <span class="text-lg font-medium">Like</span>
      </div>
      <div class="flex items-center gap-1">
        <span class="text-lg font-medium text-gray-80"
          >{{ `${commandOrControl} ` }} + Shift + E:</span
        >
        <span class="text-lg font-medium">Retweet</span>
      </div>
      <div class="flex items-center gap-1">
        <span class="text-lg font-medium text-gray-80"
          >{{ `${commandOrControl} ` }} + Shift + U:</span
        >
        <span class="text-lg font-medium">Quote tweet</span>
      </div>
      <div class="flex items-center gap-1">
        <span class="text-lg font-medium text-gray-80"
          >{{ `${commandOrControl} ` }} + Shift + K:</span
        >
        <span class="text-lg font-medium">Skip</span>
      </div>
      <div class="flex items-center justify-between">
        <new-button @click="$emit('close')">Got it</new-button>
      </div>
    </div>
  </base-modal>
</template>
