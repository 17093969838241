<template>
  <Transition
    move-class="transition-all duration-500 ease-in-out"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    enter-active-class="transition-all duration-500 ease-in-out"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
    leave-active-class="transition-all duration-500 ease-in-out"
  >
    <div
      v-show="isVisible"
      data-cy="new-feed-item"
      class="border mb-6 rounded-2xl border-ds-outline-primary bg-ds-background p-6 shadow-xs"
    >
      <tweet-preview v-if="originalTweetInfo" :originalTweetInfo="originalTweetInfo" />
      <div class="space-y-6" :class="{ 'mention-container': isMention, 'mt-2': originalTweetInfo }">
        <div class="flex w-full items-center justify-between gap-x-2">
          <div class="flex-grow flex min-w-0 items-center gap-2">
            <a
              :href="`https://twitter.com/${tweet.user.username}`"
              class="flex-shrink-0"
              target="_blank"
              tabindex="-1"
            >
              <img
                :alt="`${tweet.user.name}'s profile picture`"
                :src="tweet.user.profile_image_url"
                class="avatar-img"
              />
              <span class="sr-only">twitter account</span>
            </a>
            <div
              class="flex min-w-0 flex-col items-start gap-x-2 md:flex-row md:flex-wrap md:items-center md:justify-between"
            >
              <a
                class="avatar-name"
                :href="'https://twitter.com/' + tweet.user.username"
                target="_blank"
                tabindex="-1"
              >
                {{ tweet.user.name }}
              </a>
              <a
                class="avatar-username"
                :href="`https://twitter.com/${tweet.user.username}`"
                target="_blank"
                tabindex="-1"
              >
                @{{ tweet.user.username }}
              </a>
            </div>
          </div>
          <div
            v-if="tweet.isHigherEngaging"
            class="flex items-center space-x-2 rounded-xl bg-ds-button-secondary-background px-4 py-1 text-ds-button-icon"
          >
            <inline-svg src="./logo.svg" class="h-5 w-5" />
            <span class="whitespace-pre">Higher engagement</span>
          </div>
        </div>
        <div
          class="max-w-md whitespace-pre-wrap break-words text-base leading-6 md:text-lg"
          v-html="formatTweet(tweet.text)"
          dir="auto"
          data-cy="new-feed-tweet-text"
        />
        <div class="flex items-center gap-1">
          <div class="flex space-x-2" v-if="tweetMedia.length">
            <div v-for="media in tweetMedia" v-bind:key="media.url" class="h-12 w-12 cursor-pointer">
              <img
                :src="media.preview_image_url || media.url"
                @click="expandMedia(media.url, media.type)"
                class="h-full w-full object-cover"
              />
            </div>
          </div>
        </div>
        <div class="flex justify-between gap-x-2">
          <div class="flex items-center gap-4">
            <span
              data-cy="dm-reply-count"
              class="flex flex-col items-center gap-2 text-dark-mode-10 sm:flex-row"
            >
              <inline-svg src="img/icons/like.svg" />
              {{ countFormatter(tweet.likes, 1) }}
            </span>
            <span
              data-cy="dm-retweet-count"
              class="flex flex-col items-center gap-2 text-dark-mode-10 sm:flex-row"
            >
              <inline-svg src="img/icons/new-retweet.svg" />
              {{ countFormatter(tweet.retweets, 1) }}
            </span>
          </div>
          <div>
            <div class="flex items-center gap-x-3">
              <tooltip v-if="isSearchFeed" :content="tweet.searchTerm">
                <div>
                  <inline-svg src="/img/icons/search-new-ui.svg" class="h-4 w-4 stroke-current" />
                </div>
              </tooltip>
              <a
                target="_blank"
                :href="`https://twitter.com/${tweet.user.username}/status/${tweet.id}`"
                tabindex="-1"
              >
                {{ formattedCreatedAt }}
              </a>
              <a
                class="text-main-color-100"
                target="_blank"
                :href="`https://twitter.com/${tweet.user.username}/status/${tweet.id}`"
                tabindex="-1"
              >
                View on Twitter
              </a>
            </div>
          </div>
        </div>
        <div class="relative flex">
          <textarea
            v-model="replyText"
            rows="3"
            placeholder="What would you like to say?"
            :maxlength="tweetCharactersLimit.toString()"
            data-tweet-count="0"
            class="form-field"
            ref="reply-textarea"
            @keydown="handleKeydown"
            dir="auto"
            tabindex="0"
            data-cy="new-feed-text-area"
          ></textarea>
          <div class="absolute bottom-0 right-0 p-2">
            <character-counter
              :maximumCharacters="tweetCharactersLimit"
              :textLength="tweetLength"
            />
          </div>
        </div>
        <div class="flex items-center md:justify-between">
          <new-drop-down icon data-cy="new-feed-actions">
            <new-button type="dropdown" @click="like(tweet.id)" data-cy="new-feed-like">
              <inline-svg src="/img/icons/like.svg" />
              Like
            </new-button>
            <new-button
              @click="retweet(tweet.id)"
              class="new-dropdown-item dark-mode-dropdown-item"
              data-cy="new-feed-retweet"
              type="dropdown"
            >
              <inline-svg src="/img/icons/new-retweet.svg" />
              Retweet
            </new-button>
            <hr />
            <span class="mt-0.5 px-4 py-2 text-dark-mode-15">Like and post emoji</span>
            <quick-reply-emojis
              @reply-and-like="(emoji) => replyAndLike(tweet, emoji)"
              class="px-4"
            />
          </new-drop-down>
          <div class="grid grid-cols-3 items-center gap-4">
            <new-button data-cy="new-feed-ignore" type="secondary" @click="ignoreTweet(tweet.id)">
              Skip
            </new-button>
            <new-button
              :disabled="replyTextLength === 0"
              @click="quoteTweet(tweet)"
              data-cy="new-feed-quote-tweet"
              type="secondary"
            >
              Quote <span class="ml-1 hidden sm:inline"> tweet</span>
            </new-button>
            <new-button
              :disabled="replyTextLength === 0"
              @click="reply(tweet)"
              data-cy="new-feed-reply"
            >
              Reply
            </new-button>
          </div>
        </div>
      </div>
      <modal @close="closeMediaPreview" :show="isMediaExpanded">
        <div slot="header" class="modal-title">Media Preview</div>
        <div v-if="['photo', 'animated_gif'].includes(selectedMediaType)">
          <img class="mx-auto rounded-md" :src="selectedMediaURL" alt="tweet media" />
        </div>
        <div v-else-if="selectedMediaType === 'video'" class="flex justify-center">
          <video disableRemotePlayback controls>
            <source :src="selectedMediaURL" />
          </video>
        </div>
      </modal>
    </div>
  </Transition>
</template>

<script>
  import controller from '../controller';
  import { countTweetLength } from '@/../functions/src/util/countTweetLength';
  import dao from '@/dao';
  import { formatTweet } from '@/util/formatTweet';
  import { mapGetters } from 'vuex';
  import MobileMixin from '@/views/Mixins/MobileMixin.vue';
  import moment from 'moment';
  import { countFormatter } from '@/util/countFormatter';
  import QuickReplyEmojis from './QuickReplyEmojis.vue';
  import SwalModalMixin from '../views/Mixins/SwalModalMixin.vue';
  import TweetPreview from './TweetPreview.vue';
  import NewDropDown from '@/components/NewDropDown.vue';
  import BreakpointsMixin from '@/views/Mixins/BreakpointsMixin.vue';
  import CharacterCounter from './CharacterCounter.vue';
  import { EventBus } from '@/event-bus';
  import TweetCharactersLimitMixin from '../views/Mixins/TweetCharactersLimitMixin.vue';

  export default {
    name: 'new-feed-item',
    components: { QuickReplyEmojis, TweetPreview, NewDropDown, CharacterCounter },
    data() {
      return {
        isMediaExpanded: false,
        replyText: '',
        selectedMediaURL: null,
        selectedMediaType: null,
        isVisible: true,
      };
    },
    props: {
      isMention: {
        type: Boolean,
        default: false,
      },
      tweet: {
        type: Object,
        required: true,
      },
      isSearchFeed: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile', currentUser: 'getCurrentUser' }),
      replyTextLength() {
        return this.replyText.trim().length;
      },
      tweetMedia() {
        return this.tweet.media || [];
      },
      originalTweetInfo() {
        if (!this.tweet.originalTweet) {
          return null;
        } else {
          return {
            username: this.tweet.originalTweet.user.username,
            userDisplayName: this.tweet.originalTweet.user.name,
            userProfilePictureURL: this.tweet.originalTweet.user.profile_image_url,
            text: this.tweet.originalTweet.text,
            createdAt: this.tweet.originalTweet.createdAt,
            id: this.tweet.originalTweet.id,
          };
        }
      },
      formattedCreatedAt() {
        if (this.isSmallScreen) {
          return moment(this.tweet.createdAt).format('MM-DD-YY');
        }
        return moment(this.tweet.createdAt).format('h:mm A · MMM DD, YYYY');
      },
      tweetLength() {
        return countTweetLength(this.replyText);
      },
    },
    methods: {
      closeMediaPreview() {
        this.isMediaExpanded = false;
        this.selectedMediaURL = null;
        this.selectedMediaType = null;
      },
      countFormatter(count, digits) {
        return countFormatter(count, digits);
      },
      expandMedia(mediaURL, mediaType) {
        this.isMediaExpanded = true;
        this.selectedMediaURL = mediaURL;
        this.selectedMediaType = mediaType;
      },
      focus() {
        this.$refs['reply-textarea'].focus();
      },
      formatTweet(tweetText) {
        return formatTweet(tweetText);
      },
      handleKeydown(event) {
        if ((event.metaKey || event.ctrlKey) && event.shiftKey && event.key === 'Enter') {
          this.replyAndLike(this.tweet);
        } else if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
          this.reply(this.tweet);
        } else if (
          (event.metaKey || event.ctrlKey) &&
          event.shiftKey &&
          event.key.toLowerCase() === 'k'
        ) {
          this.ignoreTweet(this.tweet.id);
        } else if (
          (event.metaKey || event.ctrlKey) &&
          event.shiftKey &&
          event.key.toLowerCase() === 'e'
        ) {
          this.retweet(this.tweet.id);
        } else if (
          (event.metaKey || event.ctrlKey) &&
          event.shiftKey &&
          event.key.toLowerCase() === 'u'
        ) {
          this.quoteTweet(this.tweet);
        } else if (
          (event.ctrlKey && event.shiftKey && event.key.toLowerCase() === 'l') ||
          (event.metaKey && event.shiftKey && event.key.toLowerCase() === 'o')
        ) {
          this.like(this.tweet.id);
        }
      },
      hideTweet(tweetId) {
        this.isVisible = false;
        setTimeout(() => {
          try {
            if (this.isMention) {
              dao.userProfile.hideReply(this.userProfile.uid, tweetId);
            } else {
              dao.userProfile.hideTweet(this.userProfile.uid, tweetId, this.isSearchFeed);
            }
            EventBus.$emit('hide-tweet', { id: tweetId, type: this.tweet.type });
          } catch (error) {
            this.swalModal({
              title: 'Error',
              text: 'Sorry, an error ocurred while trying to hide the tweet.',
              type: 'error',
            });
          }
        }, 500);
      },
      ignoreTweet(tweetId) {
        this.hideTweet(tweetId);
        if (this.isMention) {
          this.$eventStore.ignoreMention();
        } else {
          this.$eventStore.engagementBuilder.ignoreTweet();
        }
      },
      like(tweetId) {
        try {
          controller.twitter.likeTweet(this.currentUser, this.userProfile.uid, tweetId);
          if (this.isMention) {
            this.$eventStore.likeMention();
          } else {
            this.$eventStore.engagementBuilder.likeTweet();
          }
          this.$notify({ type: 'success', message: 'Liked!' });
        } catch (error) {
          console.error(error);
          this.swalModal({
            title: 'Error',
            text: 'Sorry, an error has occurred while trying to like the tweet.',
            type: 'error',
          });
        }
      },
      quoteTweet(tweet) {
        if (this.replyTextLength === 0) {
          return null;
        }
        const quoteTweetId = tweet.id;
        try {
          controller.twitter.quoteTweet(
            this.currentUser,
            this.userProfile.uid,
            this.replyText,
            quoteTweetId
          );
          if (this.isMention) {
            this.$eventStore.quoteTweetAMention();
          } else {
            this.$eventStore.engagementBuilder.quoteTweet();
          }
          this.$notify({ type: 'success', message: 'Quoted!' });
        } catch (error) {
          console.error(error);
          this.swalModal({
            title: 'Error',
            text: 'Sorry, an error has occurred while trying to quote tweet.',
            type: 'error',
          });
        }
        this.hideTweet(tweet.id);
      },
      replyAndLike(tweet, emoji) {
        controller.twitter
          .likeTweet(this.currentUser, this.userProfile.uid, tweet.id)
          .catch((error) => {
            console.error(error);
            this.swalModal({
              title: 'Error',
              text: 'Sorry, an error has occurred while sending like.',
              type: 'error',
            });
          });
        if (emoji) {
          this.replyText = emoji;
        }
        return this.reply(tweet);
      },
      reply(tweet) {
        try {
          controller.twitter.replyToTweet(
            this.currentUser,
            this.userProfile.uid,
            tweet.id,
            this.replyText
          );
          if (this.isMention) {
            this.$eventStore.replyToAMention();
          } else {
            this.$eventStore.engagementBuilder.replyToATweet();
          }
          this.$notify({ type: 'success', message: 'Reply sent successfully!' });
        } catch (error) {
          console.error(error);
          this.swalModal({
            title: 'Error',
            text: 'Sorry, an error has occurred while trying to post your reply.',
            type: 'error',
          });
        }
        this.hideTweet(tweet.id);
      },
      retweet(tweetId) {
        try {
          controller.twitter.retweet(this.currentUser, this.userProfile.uid, tweetId);
          if (this.isMention) {
            this.$eventStore.retweetMention();
          } else {
            this.$eventStore.engagementBuilder.retweet();
          }
          this.$notify({ type: 'success', message: 'Retweeted!' });
        } catch (error) {
          console.error(error);
          this.swalModal({
            title: 'Error',
            text: 'Sorry, an error has occurred while trying to retweet.',
            type: 'error',
          });
        }
      },
    },
    mixins: [MobileMixin, SwalModalMixin, BreakpointsMixin, TweetCharactersLimitMixin],
  };
</script>

<style lang="scss" scoped>
  .feed-item {
    @apply bg-card p-5 rounded-lg mb-4;

    @screen md {
      @apply p-6 flex;

      &-tweet-container,
      &-reply {
        @apply w-1/2;
      }
    }

    @screen lg {
      @apply block;

      &-tweet-container,
      &-reply {
        @apply w-full;
      }
    }

    @screen xl {
      @apply p-6 flex;

      &-tweet-container,
      &-reply {
        @apply w-1/2;
      }
    }

    &-tweet {
      @apply w-full flex relative;

      a {
        @apply text-current;
      }

      &:not(:last-child) {
        @apply pb-3;

        &::after {
          content: '';
          @apply block absolute ml-3 bg-divider;
          transform: translateX(-0.5px);
          top: 1.8rem;
          bottom: 0;
          width: 1px;
        }
      }

      &-avatar {
        @apply flex-shrink-0;
      }

      &-username {
        @apply text-base;
      }

      &-time {
        @apply text-secondary;
      }

      &-content {
        @apply w-full;
      }

      &-text {
        @apply whitespace-pre-wrap break-words;
      }
    }

    .feed-item-user-name {
      @apply text-secondary #{!important};
    }

    .view-on-twitter {
      @apply text-accent #{!important};
    }

    .username-container {
      flex-basis: 100%;
    }
  }

  .new-ui {
    .mention-container {
      @apply border rounded-lg p-6;
    }
    &.mode--light {
      .mention-container {
        @apply border-gray-60;
      }
    }
    &.mode--dark {
      .mention-container {
        @apply border-dark-mode-50;
      }
    }
  }
</style>
